<template>
  <v-footer color="light-blue darken-4" dark padless>
    <v-spacer></v-spacer>
    <div class="ma-2">Copyright &copy; {{ copyrightYear }} My Pottery Shed</div>
    <!-- <v-layout row wrap>
        <v-flex xs12 sm6 md4>
          <v-col>
            <v-list-item-group>
              <template v-for="(item, index) in footerColumnOne">
                  <v-list-item :key="index">
                      <v-list-item-content>
                          {{item.title}}
                      </v-list-item-content>
                  </v-list-item>
              </template>
          </v-list-item-group>
          </v-col>
        </v-flex>
        <v-flex xs12 sm6 md4>
          <v-col>
            <v-list-item-group>
              <template v-for="(item, index) in footerColumnTwo">
                  <v-list-item :key="index">
                      <v-list-item-content>
                          {{item.title}}
                      </v-list-item-content>
                  </v-list-item>
              </template>
          </v-list-item-group>
          </v-col>
        </v-flex>
        <v-flex xs12 sm6 md4>
          <v-col>
            <v-list-item-group>
              <template v-for="(item, index) in footerColumnThree">
                  <v-list-item :key="index">
                      <v-list-item-content>
                          {{item.title}}
                      </v-list-item-content>
                  </v-list-item>
              </template>
          </v-list-item-group>
          </v-col>
        </v-flex>
    </v-layout> -->
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
  data: () => ({
    copyrightYear: new Date().getFullYear(),
    footerColumnOne: [{ title: "FAQs" }],
    footerColumnTwo: [
      { title: "Privacy Policy" },
      { title: "Terms & Conditions" }
    ],
    footerColumnThree: [{ title: "Returns" }]
  })
};
</script>

<style scoped></style>
