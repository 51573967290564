<template>
  <span>
    <!-- <v-navigation-drawer app v-model="drawer" class="light-blue lighten-2"
                            dark disable-resize-watcher>
            <v-list>
                <template v-for="(item, index) in items">
                    <v-list-item :key="index">
                        <router-link v-bind:to="item.link">
                            <v-list-item-content>
                                {{item.title}}
                            </v-list-item-content>
                        </router-link>
                    </v-list-item>
                    <v-divider :key="`divider-${index}`"></v-divider>
                </template>
            </v-list>
        </v-navigation-drawer> -->
    <v-toolbar color="light-blue darken-4" dark>
      <!-- <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer">
            </v-app-bar-nav-icon> -->
      <router-link to="/">
        <v-btn text icon>
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </router-link>
      <router-link to="/">
        <v-toolbar-title to="/">{{ appTitle }}</v-toolbar-title>
      </router-link>
      <!-- <v-spacer class="hidden-sm-and-down"></v-spacer>
            <v-btn text icon class="hidden-sm-and-down">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn text icon class="hidden-sm-and-down">
                <v-icon>mdi-account</v-icon>
            </v-btn> -->
    </v-toolbar>
  </span>
</template>

<script>
export default {
  name: "TitleBar",
  data() {
    return {
      appTitle: "My Pottery Shed",
      drawer: false,
      items: [
        { title: "Home", link: "/" },
        { title: "About Me", link: "/about" },
        { title: "Contact Me", link: "/contact" },
        { title: "News", link: "/news" },
        { title: "Gallery", link: "/gallery" }
      ]
    };
  }
};
</script>

<style scoped>
.v-application a {
  color: white;
  text-decoration: none;
}
</style>
