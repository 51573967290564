<template>
  <v-app>
    <title-bar></title-bar>
    <!-- <app-navigation></app-navigation> -->
    <v-content transition="slide-x-transition">
      <router-view></router-view>
    </v-content>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
// import AppNavigation from '@/components/AppNavigation.vue';
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: "App",
  components: {
    TitleBar,
    // AppNavigation,
    AppFooter
  }
};
</script>

<style>
#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
