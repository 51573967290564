<template>
  <div id="main" class="ma-2">
    <v-btn class="mx-2" fab small :href="facebookPage">
      <v-icon>mdi-facebook</v-icon>
    </v-btn>
    <v-btn class="mx-2" fab small :href="instagramPage">
      <v-icon>mdi-instagram</v-icon>
    </v-btn>
    <v-btn class="mx-2" fab small :href="`mailto:${emailAddress}`">
      <v-icon>mdi-email</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SocialMediaBar",
  data: () => ({
    facebookPage: "https://www.facebook.com/pg/mypotteryshed/",
    instagramPage: "https://www.instagram.com/mypotteryshed/",
    emailAddress: "mypotteryshed@outlook.com"
  })
};
</script>

<style scoped></style>
