/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import firebase from "firebase/app";
import "firebase/firestore";

const numberOfProductsPerPage = 15;
const totalNumberOfPages = 0;

let filteredProductDocuments = [];

function getProductsFromFirebase(commit, filters = []) {
  const collectionReference = firebase.firestore().collection("Products");

  let query = collectionReference;
  if (filters.length !== 0) {
    query = collectionReference.where(
      "Category",
      "array-contains-any",
      filters
    );
  }

  const products = [];
  query
    .orderBy("ImageUrl")
    .get()
    .then(querySnapshot => {
      filteredProductDocuments = querySnapshot.docs;
      let currentDocumentIndex = 0;
      while (currentDocumentIndex < numberOfProductsPerPage) {
        if (filteredProductDocuments[currentDocumentIndex]) {
          const imageUrl = filteredProductDocuments[currentDocumentIndex].get(
            "ImageUrl"
          );
          products.push(imageUrl);
        }
        currentDocumentIndex += 1;
      }
      commit("setProducts", products);
      commit(
        "setTotalNumberOfPages",
        Math.ceil(querySnapshot.size / numberOfProductsPerPage)
      );
    })
    .catch(() => {
      // console.log('Error getting documents: ', error);
    });
}

function fetchPage(commit, pageNumber = 1) {
  let startingIndex = 0;

  if (pageNumber !== 1) {
    startingIndex =
      numberOfProductsPerPage * pageNumber - numberOfProductsPerPage;
  }

  const products = [];
  let currentDocumentIndex = 0;
  while (currentDocumentIndex < numberOfProductsPerPage) {
    const offset = startingIndex + currentDocumentIndex;
    if (filteredProductDocuments[offset]) {
      const imageUrl = filteredProductDocuments[offset].get("ImageUrl");
      products.push(imageUrl);
    }
    currentDocumentIndex += 1;
  }

  commit("setProducts", products);
}

const state = {
  productCategories: [],
  products: [],
  totalNumberOfProductPages: 0,
  totalNumberOfPages: 0
};

const getters = {};

const actions = {
  getProductCategories({ commit }) {
    const categories = [];
    firebase
      .firestore()
      .collection("Categories")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          categories.push(doc.id);
        });
      });
    commit("setProductCategories", categories);
  },
  getProducts({ commit }) {
    getProductsFromFirebase(commit);
  },
  filterProducts({ commit }, filters) {
    getProductsFromFirebase(commit, filters.filters);
  },
  fetchPage({ commit }, pageNumber) {
    fetchPage(commit, pageNumber.pageNumber);
  },
  getTotalNumberOfPages() {
    return totalNumberOfPages;
  }
};

const mutations = {
  setProductCategories(state, payload) {
    state.productCategories = payload;
  },
  setProducts(state, payload) {
    state.products = payload;
  },
  setTotalNumberOfProductPages(state, payload) {
    state.totalNumberOfProductPages = payload;
  },
  setTotalNumberOfPages(state, payload) {
    state.totalNumberOfPages = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
