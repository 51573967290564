<template>
  <div id="main">
    <div v-show="loading" id="loader">
      <p>Loading</p>
    </div>
    <div id="container" :class="{ hidden: loading }">
      <stack
        :column-min-width="300"
        :gutter-width="15"
        :gutter-height="15"
        monitor-images-loaded
        @images-loaded="loading = false"
      >
        <stack-item
          v-for="(image, i) in images"
          :key="i"
          style="transition: transform 300ms"
        >
          <img :src="image" />
        </stack-item>
      </stack>
    </div>
  </div>
</template>

<script>
import { Stack, StackItem } from "vue-stack-grid";

export default {
  name: "ImageGrid",
  components: {
    Stack,
    StackItem
  },
  props: ["images"],
  data: () => ({
    filters: [],
    loading: true
  })
};
</script>

<style scoped>
#container {
  width: 80vw;
  margin: 0 auto;
}
img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  max-width: 80vw;
}
.hidden {
  opacity: 0;
}
</style>
