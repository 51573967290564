// eslint-disable-next-line no-unused-vars
const firebaseConfig = {
  apiKey: "AIzaSyADqzAf0wHJd3PVNoR7kGJtpwWaBaCmvZc",
  authDomain: "my-pottery-shed.firebaseapp.com",
  databaseURL: "https://my-pottery-shed.firebaseio.com",
  projectId: "my-pottery-shed",
  storageBucket: "my-pottery-shed.appspot.com",
  messagingSenderId: "995622180887",
  appId: "1:995622180887:web:9bf87836b328bb81712452",
  measurementId: "G-3XHEW0X871"
};
module.exports = { firebaseConfig };
