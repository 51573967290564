<template>
  <v-container fluid>
    <v-layout column>
      <h1>Gallery</h1>
      <social-media-bar></social-media-bar>
      <div class="filter-bar mb-2">
        <v-chip-group
          v-model="filters"
          multiple
          column
          active-class="primary--text"
        >
          <v-chip
            v-for="(item, idx) in productCategories"
            :key="idx"
            class="ma-2"
            :value="item"
            filter
          >
            {{ item }}
          </v-chip>
        </v-chip-group>
      </div>
      <image-grid :images="products"></image-grid>
      <v-pagination
        v-model="currentPageNumber"
        class="my-4"
        :length="totalNumberOfPages"
      ></v-pagination>
    </v-layout>
  </v-container>
</template>

<script>
import ImageGrid from "@/components/Gallery/ImageGrid.vue";
import SocialMediaBar from "@/components/SocialMediaBar.vue";

export default {
  name: "Gallery",
  components: {
    ImageGrid,
    SocialMediaBar
  },
  data: () => ({
    filters: [],
    currentPageNumber: 1
  }),
  computed: {
    productCategories() {
      return this.$store.state.products.productCategories;
    },
    products() {
      return this.$store.state.products.products;
    },
    totalNumberOfPages() {
      return this.$store.state.products.totalNumberOfPages;
    }
  },
  watch: {
    filters(val) {
      if (val) {
        this.$store.dispatch("products/filterProducts", {
          filters: val
        });
      }
      this.currentPageNumber = 1;
    },
    currentPageNumber(val) {
      this.$store.dispatch("products/fetchPage", {
        pageNumber: val
      });
    }
  },
  created() {
    this.getProductCategories();
    this.getProducts();
    this.getTotalNumberOfPages();
  },
  methods: {
    getProductCategories() {
      this.$store.dispatch("products/getProductCategories");
    },
    getProducts() {
      this.$store.dispatch("products/getProducts");
    },
    getTotalNumberOfPages() {
      this.$store.dispatch("products/getTotalNumberOfPages");
    }
  }
};
</script>

<style scoped>
.filter-bar {
  display: flex;
  justify-content: center;
}
</style>
