import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue';
import Gallery from "../views/Gallery.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Gallery
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('../views/About.vue'),
  // },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: () => import('../views/Contact.vue'),
  // },
  // {
  //   path: '/gallery',
  //   name: 'gallery',
  //   component: () => import('../views/Gallery.vue'),
  // },
  // {
  //   path: '/news',
  //   name: 'news',
  //   component: () => import('../views/News.vue'),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
